import React, { useEffect, useState } from "react";
import "animate.css";
import WOW from "wowjs";
import BoardCard from "./BoardCard";
import "./SuperHeroes.css";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

function SuperHeroes() {
    const [members, setMembers] = useState([]);

    useEffect(() => {
        // Initialize WOW.js for animations
        const wow = new WOW.WOW({ live: false });
        wow.init();

        // Fetch members data
        const fetchMembers = async () => {
            try {
                const response = await axios.get("https://jciamravati.in/api/v1/team/getteam");
                // Filter only "LGB member" roles
                const pastPresidents = response.data.data.filter((member) => member.role === "LGB member");
                setMembers(pastPresidents);
            } catch (error) {
                console.error("Error fetching members data:", error);
            }
        };

        fetchMembers();
    }, []);

    return (
        <Container className="zone-container text-center">
            <h1>LGB Member</h1>

            <Row>
                {members.length > 0 ? (
                    members.map((member, index) => {
                        // Define layout for first two members
                        const colProps = index < 2 ? { xs: 6, sm: 6, md: 6, lg: 6 } : { xs: 6, sm: 6, lg: 3 };

                        return (
                            <Col
                                {...colProps}
                                key={index}
                                className="wow animate__animated animate__fadeIn"
                            >
                                <BoardCard
                                    name={member.name}
                                    title={member.post}
                                    image={`https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati/${member.imagename.split(",")[0]}`}
                                />
                            </Col>
                        );
                    })
                ) : (
                    <p className="text-muted">No Members available.</p>
                )}
            </Row>
        </Container>
    );
}

export default SuperHeroes;
