import React, { useState, useEffect } from "react";
import "./Workareas.css";
import { ChevronLeft } from "lucide-react";

// Details Component
const ActivityDetails = ({ activity, onClose }) => {
    const [imageUrls, setImageUrls] = useState([]);

    useEffect(() => {
        // Check if the activity has imagename (image filenames) and fetch URLs
        if (activity.imagename) {
            const fetchImageUrls = async () => {
                try {
                    // Base URL for the images
                    const baseUrl =
                        "https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati";

                    // Split the imagename string into individual filenames
                    const imageNames = activity.imagename.split(",");

                    // Create the full URLs for each image
                    const mappedImages = imageNames.map((name) => ({
                        url: `${baseUrl}/${name}?q=50%&&f=webp`,
                        id: name,
                    }));

                    // Update the state with the full image URLs
                    setImageUrls(mappedImages);
                } catch (err) {
                    console.error("Error fetching image URLs:", err);
                }
            };

            fetchImageUrls();
        }
    }, [activity]);

    return (
        <div className="activity-details bg-light shadow-lg p-4 rounded">
            <div className="activity-header d-flex align-items-center mb-4">
                <button onClick={onClose} className="btn work-button me-auto">
                    <ChevronLeft />
                </button>
                <h1 className="text-uppercase m-0 text-center flex-grow-1">
                    {activity.title}
                </h1>
            </div>

            <div className="text-secondary">
                <p className="fs-5 fw-semibold">Date: {activity.date}</p>
                <div className="text-md text-white">
                    <span className="font-bold block">Description:</span>
                    {activity.description.split("\n").map((paragraph, index) => (
                        <p key={index} className="fs-5">
                            {paragraph}
                        </p>
                    ))}
                </div>

                {/* Render Images */}
                <div className="activity-images mt-4">
                    <div className="d-flex flex-wrap gap-3 justify-content-center">
                        {imageUrls.length > 0 ? (
                            imageUrls.map((image, index) => (
                                <img
                                    key={index}
                                    src={image.url}
                                    alt={`Activity Image ${index + 1}`}
                                    className="rounded shadow-sm"
                                    style={{ width: "360px", height: "250px", objectFit: "cover" }}
                                />
                            ))
                        ) : (
                            <p className="text-muted">No images available</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Workdevelopment = () => {
    const [filter, setFilter] = useState("all");
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await fetch(
                    "https://jciamravati.in/api/v1/workingareas/getDevelopment"
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch activities");
                }
                const data = await response.json();
                setActivities(data.data || []);
            } catch (err) {
                console.error("Error fetching activities:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, []);

    const filteredActivities =
        filter === "all"
            ? activities
            : activities.filter((item) => item.date.includes(filter));

    return (
        <div className="container-fluid workmanagement-parent py-5">
            <div className="container">
                {selectedActivity ? (
                    <ActivityDetails
                        activity={selectedActivity}
                        onClose={() => setSelectedActivity(null)}
                    />
                ) : (
                    <div className="text-center">
                        <h2 className="mb-4">
                            WORKING AREA : INTERNATIONAL GROWTH & DEVELOPMENT
                        </h2>

                        <div className="d-flex justify-content-center mb-4 flex-wrap gap-2">
                            <button
                                className="work-button me-2"
                                onClick={() => setFilter("all")}
                            >
                                All Activities
                            </button>
                            <button
                                className="work-button me-2"
                                onClick={() => setFilter("2025")}
                            >
                                2025
                            </button>
                            <button
                                className="work-button"
                                onClick={() => setFilter("2024")}
                            >
                                2024
                            </button>
                        </div>

                        {loading ? (
                            <p className="text-muted">Loading activities...</p>
                        ) : error ? (
                            <p className="text-danger">{error}</p>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-striped table-hover shadow-sm rounded">
                                    <thead className="table-primary">
                                    <tr>
                                        <th className="text-center py-3">Date</th>
                                        <th className="text-center py-3">Event</th>
                                        <th className="text-center py-3">Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredActivities.map((activity, index) => (
                                        <tr key={index}>
                                            <td className="text-center align-middle">
                                                {activity.date || "N/A"}
                                            </td>
                                            <td className="text-center align-middle">
                                                {activity.title || "Untitled"}
                                            </td>
                                            <td className="text-center align-middle">
                                                <button
                                                    onClick={() => setSelectedActivity(activity)}
                                                    className="read-more"
                                                >
                                                    Read More
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Workdevelopment;
