import React, { useEffect } from "react";
import './JoinContainer.css'
import 'animate.css';
import WOW from 'wowjs';



const JoinContainer = () => {
    useEffect(() => {
      const wow = new WOW.WOW({ live: false });
      wow.init();
    }, []);
    return (
        <div class="container-fluid join-parent-container">


            <div class="container join-child-container">
                <h4>WHY JOIN JCI</h4>
                <p>Our activities that chisel a better tomorrow</p>

                <div class="container mt-4">
                    <div class="row">


                        <div class="col-4 col-md-2 mb-2 wow animate__animated animate__fadeInUp">
                            <div class="icons-content d-flex align-items-center">
                              <div class="image">
                                <img src="/assets/join-images/leadership_860472.png" alt="" class="img-fluid" />
                              </div>
                              <p>Leadership development programs</p>
                            </div>
                        </div>

                        <div class="col-4 col-md-2 mb-2 wow animate__animated animate__fadeInDown">
                          <div class="icons-content d-flex align-items-center">
                            <div class="image">
                              <img src="/assets/join-images/self-dev.png" alt="" class="img-fluid" />
                            </div>
                            <p>Self-development trainings</p>
                          </div>
                        </div>

                        <div class="col-4 col-md-2 mb-2 wow animate__animated animate__fadeInUp">
                          <div class="icons-content d-flex align-items-center">
                            <div class="image">
                              <img src="/assets/join-images/bus-dev.png" alt="" class="img-fluid" />
                            </div>
                            <p>Business development connections</p>
                          </div>
                        </div>


                        <div class="col-4 col-md-2 mb-2 wow animate__animated animate__fadeInDown">
                          <div class="icons-content d-flex align-items-center">
                            <div class="image">
                                <img src="/assets/join-images/com.png" alt="" />
                            </div>
                            <p>Community benefitting projects</p>
                          </div>
                        </div>


                        <div class="col-4 col-md-2 mb-2 wow animate__animated animate__fadeInUp">
                          <div class="icons-content d-flex align-items-center">
                            <div class="image">

                                <img src="/assets/join-images/team.png" alt="" />
                            </div>
                            <p>Uplifting the economically deprived</p>
                          </div>
                        </div>

                        <div class="col-4 col-md-2 mb-2 wow animate__animated animate__fadeInDown">
                          <div class="icons-content d-flex align-items-center">
                            <div class="image">
                                <img src="assets/join-images/earth.png" alt="" />
                            </div>
                            <p>Together building a better world</p>
                          </div>
                        </div>

                    </div>
                
                
                </div>

            </div>

    
        </div>
    );
};



export default JoinContainer;