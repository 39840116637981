// Importing React and necessary hooks
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import './Form.css'

const Form = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Dob: "",
    Bloodgroup: "",
    Education: "",
    Occupation: "",
    Postaladdress: "",
    Mobileno: "",
    Mstatus: "Single",
    Wifename: "",
    Wdob: "",
    Wmobileno: "",
    Childname: "",
    Occupationdetail: "",
    Address: "",
    Expectation: "",
    Jcname: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://jciamravati.in/api/v1/membership/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        alert("Form submitted successfully!");
      } else {
        alert(`Failed to submit form: ${result.message}`);
      }
    } catch (error) {
      alert("An error occurred while submitting the form.");
      console.error(error);
    }
  };

  return (
    <div className="container form-parent">
    <div className="card p-2">
      <div className="card-header bg-primary text-white">
        <h2 className="mb-0">Membership Form</h2>
      </div>
      <div className="card-body p-4">
        <form onSubmit={handleSubmit} className="row g-4">
          {/* Personal Information Section */}
          <div className="col-12">
            <h4 className="text-primary mb-3">Personal Information</h4>
          </div>

          <div className="col-md-6">
            <label className="form-label">Your Name <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Date of Birth <span className="text-danger">*</span></label>
            <input
              type="date"
              className="form-control"
              name="Dob"
              value={formData.Dob}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Blood Group <span className="text-danger">*</span></label>
            <select
              className="form-select"
              name="Bloodgroup"
              value={formData.Bloodgroup}
              onChange={handleChange}
              required
            >
              <option value="">Select Blood Group</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
            </select>
          </div>

          {/* Education and Occupation Section */}
          <div className="col-md-6">
            <label className="form-label">Education <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              name="Education"
              value={formData.Education}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Occupation <span className="text-danger">*</span></label>
            <select
              className="form-select"
              name="Occupation"
              value={formData.Occupation}
              onChange={handleChange}
              required
            >
              <option value="">Select Occupation</option>
              <option value="Business Owner">Business Owner</option>
              <option value="Self Employed">Self Employed</option>
              <option value="Working Professional">Working Professional</option>
              <option value="Student">Student</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Contact Information Section */}
          <div className="col-12 mt-4">
            <h4 className="text-primary mb-3">Contact Information</h4>
          </div>

          <div className="col-md-6">
            <label className="form-label">Postal Address <span className="text-danger">*</span></label>
            <textarea
              className="form-control"
              name="Postaladdress"
              value={formData.Postaladdress}
              onChange={handleChange}
              rows="3"
              required
            ></textarea>
          </div>

          <div className="col-md-6">
            <label className="form-label">Mobile Number (WhatsApp) <span className="text-danger">*</span></label>
            <input
              type="tel"
              className="form-control"
              name="Mobileno"
              value={formData.Mobileno}
              onChange={handleChange}
              pattern="[0-9]{10}"
              required
            />
            <small className="text-muted">Format: 9876543210</small>
          </div>

          {/* Marital Status Section */}
          <div className="col-12 mt-4">
            <h4 className="text-primary mb-3">Marital Status</h4>
          </div>

          <div className="col-md-6">
            <label className="form-label">Marital Status <span className="text-danger">*</span></label>
            <select
              className="form-select"
              name="Mstatus"
              value={formData.Mstatus}
              onChange={handleChange}
              required
            >
              <option value="Single">Single</option>
              <option value="Married">Married</option>
            </select>
          </div>

          {formData.Mstatus === "Married" && (
            <>
              <div className="col-md-6">
                <label className="form-label">Spouse Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="Wifename"
                  value={formData.Wifename}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6">
                <label className="form-label">Spouse Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  name="Wdob"
                  value={formData.Wdob}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6">
                <label className="form-label">Spouse Mobile Number</label>
                <input
                  type="tel"
                  className="form-control"
                  name="Wmobileno"
                  value={formData.Wmobileno}
                  onChange={handleChange}
                  pattern="[0-9]{10}"
                />
              </div>
            </>
          )}

          {/* Additional Information Section */}
          <div className="col-12 mt-4">
            <h4 className="text-primary mb-3">Additional Information</h4>
          </div>

          <div className="col-md-6">
            <label className="form-label">Children's Names</label>
            <input
              type="text"
              className="form-control"
              name="Childname"
              value={formData.Childname}
              onChange={handleChange}
              placeholder="Separate names with commas"
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Occupation Details <span className="text-danger">*</span></label>
            <textarea
              className="form-control"
              name="Occupationdetail"
              value={formData.Occupationdetail}
              onChange={handleChange}
              rows="2"
              required
            ></textarea>
          </div>

          {/* Expectations and Proposal Section */}
          <div className="col-12 mt-4">
            <h4 className="text-primary mb-3">Expectations & Proposal</h4>
          </div>

          <div className="col-12">
            <label className="form-label">Your Expectations <span className="text-danger">*</span></label>
            <textarea
              className="form-control"
              name="Expectation"
              value={formData.Expectation}
              onChange={handleChange}
              rows="4"
              required
            ></textarea>
          </div>

          <div className="col-md-6">
            <label className="form-label">Proposed By (JC Name) <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              name="Jcname"
              value={formData.Jcname}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-12 mt-5">
            <button type="submit" className="btn btn-primary btn-lg w-100">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  );
};

export default Form;