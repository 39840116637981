import React, { useEffect } from "react";
import './FeaturesContainer.css';
import 'animate.css';
import WOW from 'wowjs';

import leadershippng from './assets/images/organization_12966940.png';
import youngpng from './assets/images/team_3685554.png';
import solutionpng from './assets/images/team_3685554.png';

// Create a reusable FeatureBox component
const FeatureBox = ({ iconSrc, title, description }) => {
    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    return (
        <div className="col-xl-4 col-md-6 col-sm-12 box wow animate__animated animate__fadeInUp">
            <div className="feature-icon">
                <img src={iconSrc} alt={title} />
            </div>
            <div className="text-item">
                <h5>{title}</h5>
                <p>{description}</p>
            </div>
        </div>
    );
};

const FeaturesContainer = () => {
    // Define the feature data
    const features = [
        {
            iconSrc: leadershippng,
            title: "Leadership Organization",
            description: "We develop leaders for a better tomorrow"
        },
        {
            iconSrc: solutionpng,
            title: "Sustainable Solutions",
            description: "Our members provide sustainable solutions in their region"
        },
        {
            iconSrc: youngpng,
            title: "Young Active Leaders",
            description: "We are the largest organization with young and active leaders"
        }
    ];

    return (
        <div className="container features text-center">
            <div className="row justify-content-center">
                {features.map((feature, index) => (
                    <FeatureBox
                        key={index}
                        iconSrc={feature.iconSrc}
                        title={feature.title}
                        description={feature.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default FeaturesContainer;
