import React from "react";
import './WhatContainer.css'
import WorkContainer from './WorkContainer'

const WhatContainer = () => {
    return (
        <div className="container-fluid what-parent-container">
            <div className="container what-child-container">


            <div className="what-title-text text-center text-dark">
                <h4>WHAT WE DO</h4>
                <p>We develop skills, knowledge, and leadership abilities of young citizens who go on to provide sustainable solutions that create a positive impact.</p>
            </div>


            <WorkContainer />

            </div>
           


            {/* <div className="work-items p-4">
                <div className="row mb-4">
                    <div className="col-12 box col-md-2 bg-warning text-white p-3">
                        <img src="/assets/join-images/self-dev.png" alt="Self Development" style={{ width: '50px', height: '50px' }} />
                        <p>Individiual Growth</p>
                    </div>
                    <div className="col-12 box col-md-2 bg-warning text-white p-3 d-flex align-items-center justify-content-center">
                        <img src="/assets/join-images/self-dev.png" alt="Self Development" style={{ width: '50px', height: '50px' }} />
                        <p>Analysis</p>
                    </div>
                    <div className="col-12 box col-md-2 bg-warning text-white p-3 d-flex align-items-center justify-content-center">
                        <img src="/assets/join-images/self-dev.png" alt="Self Development" style={{ width: '50px', height: '50px' }} />
                        <p>Business Entrepreneurship</p>
                    </div>
                    <div className="col-12 box col-md-2 bg-warning text-white p-3 d-flex align-items-center justify-content-center">
                        <img src=".././assets/join-images/self-dev.png" alt="Self Development" style={{ width: '50px', height: '50px' }} />
                        <p>Develop</p>
                    </div>
                    <div className="col-12 box col-md-2 bg-warning text-white p-3 d-flex align-items-center justify-content-center">
                        <img src="/assets/join-images/self-dev.png" alt="Self Development" style={{ width: '50px', height: '50px' }} />
                        <p>Community Action</p>
                    </div>
                    <div className="col-12 box col-md-2 bg-warning text-white p-3 d-flex align-items-center justify-content-center">
                        <img src=".././assets/join-images/self-dev.png" alt="Self Development" style={{ width: '50px', height: '50px' }} />
                        <p>Execute</p>
                    </div>                    
                    <div className="col-12 box col-md-2 bg-warning text-white p-3 d-flex align-items-center justify-content-center">
                        <img src="/assets/join-images/self-dev.png" alt="Self Development" style={{ width: '50px', height: '50px' }} />
                        <p>International Cooperation</p>
                    </div>
                    <div className="col-12 box col-md-2 bg-warning text-white p-3 d-flex align-items-center justify-content-center">
                        <img src=".././assets/join-images/self-dev.png" alt="Self Development" style={{ width: '50px', height: '50px' }} />
                        <p>Review</p>
                    </div>
                </div>

            </div> */}
        </div>
    );
};

export default WhatContainer;
