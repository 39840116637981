import React, { useEffect } from "react";
import './JciContainer.css';
import 'animate.css';
import WOW from 'wowjs';

import portrait from './assets/images/director-group.jpg';

const JciContainer = () => {
    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    return (
        <div className="container-fluid jci-parent-container">
            <div className="container jci-child-container flex-column flex-lg-row">
                <div className="text-content col-lg-6 col-12 wow animate__animated animate__fadeInLeft p-0">
                    <div className="heading">
                        <h4>WHAT IS JCI?</h4>
                        <h6>Developing Leaders for a Changing World</h6>
                    </div>
                    <p className="text-justify">
                        Junior Chamber International (JCI) is a worldwide federation of young leaders and entrepreneurs with nearly five lakh active members and millions of alumni spread across more than 115 countries. We are young active citizens all over the world, living, communicating, taking action, and creating impact in our communities.
                    </p>

                    <div className="lower-box">
                        <div className="mission-vision text-justify">
                           <p><span>Our Mission:</span> To provide leadership development opportunities that empower young people to create positive change.</p>  
                           <p><span>Our Vision:</span> To be the foremost global network of young leaders.</p> 
                        </div>
                    </div>
                </div>

                <div className="image col-lg-6 col-12 wow animate__animated animate__fadeInRight">
                    <img src={portrait} alt="JCI President"/>
                </div>
            </div>
        </div>
    );
};

export default JciContainer;
