import React from "react";
import './WorkContainer.css'; // Import the CSS file


import Square from './assets/images/circular.jpg'


function WorkContainer() {

  // Styles for central image and icon positioning are handled in CSS now
  
  const icons = [
    { src: '/assets/join-images/team.png', label: 'Community action' },
    { src: '/assets/join-images/execute.png', label: 'Execute' },
    { src: '/assets/join-images/int-co.png', label: 'International Cooperation' },
    { src: '/assets/join-images/review.png', label: 'Review' },
    { src: '/assets/join-images/growth.png', label: 'Individual growth' },
    { src: '/assets/join-images/analyse.png', label: 'Analyse' },
    { src: '/assets/join-images/bus-dev.png', label: 'Business & Entrepreneurship' },
    { src: '/assets/join-images/develop.png', label: 'Develop' },
  ];

  const iconCount = icons.length;
  const radius = 50;

  return (
    <div className="work-container">
      {/* Central Image */}
      <div className="central-image">
        <img
          src={Square}
          alt="Central"
          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
        />
      </div>

      {/* Icons Around the Central Image */}
      {icons.map((icon, index) => {
        const angle = (index * 360) / iconCount;
        const iconPositionStyle = {
          top: `${50 + radius * Math.sin((angle * Math.PI) / 180)}%`,
          left: `${50 + radius * Math.cos((angle * Math.PI) / 180)}%`,
          transform: 'translate(-50%, -50%)',
        };

        return (
          <div key={index} className="icon-style" style={iconPositionStyle}>
            <img
              src={icon.src}
              alt={icon.label}
              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
            />
            <div className="icon-text-style">{icon.label}</div>
          </div>
        );
      })}
    </div>
  );
}

export default WorkContainer;
