import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import './CarouselContainer.css';

function CarouselContainer() {
  const [images, setImages] = useState([]);

  // Fetch images from the API
  const fetchImages = async () => {
    try {
      const response = await axios.get("https://jciamravati.in/api/v1/carousel/images");
      console.log(response.data.imageNames);

      const baseUrl =
        "https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati";
      const mappedImages = response.data.imageNames.map((name) => ({
        url: `${baseUrl}/${name}?q=50%&&f=webp`,
        id: name, // Using the name as a unique identifier
      }));

      setImages(mappedImages);
    } catch (error) {
      console.error("Error fetching carousel images:", error.message);
      alert("Failed to fetch carousel images. Please try again.");
    }
  };

  useEffect(() => {
    fetchImages(); // Call the fetch function when the component mounts
  }, []);

  return (
    <div className="carousel-slide">
      <Carousel slide={false} interval={2000}>
        {images.length > 0 ? (
          images.map((image, index) => (
            <Carousel.Item key={image.id}>
              <img className="d-block w-100" src={image.url} alt={`Slide ${index + 1}`} />
              {/* Optional caption */}
              {/* <Carousel.Caption>
                <h3>Slide {index + 1}</h3>
                <p>Description for image {index + 1}</p>
              </Carousel.Caption> */}
            </Carousel.Item>
          ))
        ) : (
          <div>Loading images...</div> // Show loading text while images are being fetched
        )}
      </Carousel>
    </div>
  );
}

export default CarouselContainer;
