
import './YtContainer.css'; // Import the CSS file
import React, { useEffect } from "react";
import 'animate.css';
import WOW from 'wowjs';

import thumbnail from './assets/images/Youtube-thumbnail.jpg'


const thumbnails = [
  { id: 1, src: thumbnail, alt: 'Thumbnail 1', link: 'https://www.youtube.com/' },
  { id: 2, src: thumbnail, alt: 'Thumbnail 2', link: 'https://www.youtube.com/' },
  { id: 3, src: thumbnail, alt: 'Thumbnail 3', link: 'https://www.youtube.com/' }
];

function YtContainer() {
  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }, []);
  return (
    <div className="container-fluid parent-yt-container">
      <div className="container child-yt-container">
            {/* Heading */}
            <h4 className="text-center mb-4">VIDEO GALLERY</h4>

            {/* <p className="text-center mb-4">Go Through Our Video Below</p> */}

            <div className="yt-videos row justify-content-center g-3 wow animate__animated animate__fadeInUp">
              {thumbnails.map(({ id, src, alt, link }) => (
                <div className="col-12 col-md-4 col-lg-4" key={id}>
                  <div className="card">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={src}
                        className="card-img-top"
                        alt={alt}
                      />
                    </a>
                  </div>

                </div>

                

            ))}
          </div>
          <div className="viewbtn col-12 mt-5 text-center border-rounded">
                        <a href="/src/components/Gallery.js" className="btn-link">View More</a>
          </div>
        </div>
    </div>
    
  );
}

export default YtContainer;
