import './Gallery.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openImage = (imageIndex) => {
    setCurrentImage(imageIndex);
    setIsOpen(true);
  };

  const closeImage = () => {
    setIsOpen(false);
    setCurrentImage(null);
  };

  const handleNext = () => {
    const currentIndex = currentImage;
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentImage(nextIndex);
  };

  const handlePrev = () => {
    const currentIndex = currentImage;
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentImage(prevIndex);
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get("https://jciamravati.in/api/v1/gallery/images");
        console.log(response.data.imageNames);

        // Map the image names to full URLs
        const baseUrl = "https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati";
        const mappedImages = response.data.imageNames.map((name) => ({
          url: `${baseUrl}/${name}?q=50%&&f=webp`,
          id: name, // Assuming the name can be used as a unique identifier
        }));

        setImages(mappedImages);
      } catch (error) {
        console.error("Error fetching gallery images:", error.message);
        // alert("Failed to fetch gallery images. Please try again.");
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="container-fluid gallery-parent">
      <div className="container gallery-child">
        <h1>GALLERY</h1>
        <div className="row">
          {images.length === 0 && <p>No images available.</p>}
          {images.length > 0 && (
            images.map((img, index) => (
              <div key={index} className="col-6 col-sm-6 col-md-3 pb-3 pl-1">
                <img
                  src={img.url} // Use the `url` from the mapped image object
                  alt={`Gallery ${index + 1}`}
                  style={{ height: '200px', objectFit: 'cover' }}
                  onClick={() => openImage(index)}
                />
              </div>
            ))
          )}
        </div>
      </div>

      {/* Lightbox Modal */}
      {isOpen && (
        <div className="lightbox-overlay" onClick={closeImage}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <button className="lightbox-btn prev-btn" onClick={handlePrev}>&lt;</button>
            <img src={images[currentImage].url} alt="Current" className="img-fluid" /> {/* Use `url` here */}
            <button className="lightbox-btn next-btn" onClick={handleNext}>&gt;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
