import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './TopContainer.css';
import logo from './assets/images/jciamravati.png';
import memberform from './assets/Member Registration Form_20241211_120040_0000.pdf';

const TopContainer = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [dropdownsOpen, setDropdownsOpen] = useState({
    about: false,
    team: false,
    event: false,
    program: false,
    workingarea: false,
    download: false,
  });

  const menuRef = useRef(null);

  // Toggles the mobile menu
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  // Toggles specific dropdowns
  const toggleDropdown = (dropdown) => {
    setDropdownsOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  // Handles dropdowns on hover (desktop view)
  const handleHover = (dropdown, isHovered) => {
    if (!isMobile) {
      setDropdownsOpen((prev) => ({
        ...prev,
        [dropdown]: isHovered,
      }));
    }
  };

  // Event listeners for window resize and outside clicks
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="top-container">
      {/* Logo */}
      <Link to="/">
        <img src={logo} alt="Logo" className="main-logo" />
      </Link>

      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light" ref={menuRef}>
        <div className="container-fluid">
          {/* Collapsible menu */}
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <img src={logo} alt="Sidebar-Logo" className="side-bar-logo" />
            <ul className="navbar-nav">
              {/* Home */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/">Home</NavLink>
              </li>

              {/* About Us Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('about')}
                onMouseEnter={() => handleHover('about', true)}
                onMouseLeave={() => handleHover('about', false)}
              >
                <span className="nav-link dropdown-toggle" id="aboutDropdown">About Us</span>
                {dropdownsOpen.about && (
                  <ul className="dropdown-menu show">
                    <li><NavLink className="dropdown-item" to="/About">About JCI</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/AboutJCI">JCI Amravati</NavLink></li>
                    <li><a className="dropdown-item" href="https://jciindia.in/#/">JCI India</a></li>
                    <li><a className="dropdown-item" href="https://jci.cc/#/home">JCI Global</a></li>
                  </ul>
                )}
              </li>

              {/* Team Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('team')}
                onMouseEnter={() => handleHover('team', true)}
                onMouseLeave={() => handleHover('team', false)}
              >
                <span className="nav-link dropdown-toggle" id="teamDropdown">Team</span>
                {dropdownsOpen.team && (
                  <ul className="dropdown-menu show">
                    <li><NavLink className="dropdown-item" to="/GeneralMember">General Member</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/SuperHeroes">LGB Member</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Past Presidents">Past Presidents</NavLink></li>
                  </ul>
                )}
              </li>

              {/* Event Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('event')}
                onMouseEnter={() => handleHover('event', true)}
                onMouseLeave={() => handleHover('event', false)}
              >
                <span className="nav-link dropdown-toggle" id="eventDropdown">Event</span>
                {dropdownsOpen.event && (
                  <ul className="dropdown-menu show">
                    <li><NavLink className="dropdown-item" to="/">National Events</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/">Zone Events</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/">LO Events</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/">Awards Bidding Portal</NavLink></li>
                  </ul>
                )}
              </li>

              {/* Program Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('program')}
                onMouseEnter={() => handleHover('program', true)}
                onMouseLeave={() => handleHover('program', false)}
              >
                <span className="nav-link dropdown-toggle" id="programDropdown">Program</span>
                {dropdownsOpen.program && (
                  <ul className="dropdown-menu show">
                    <li><NavLink className="dropdown-item" to="/">National Program</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/">Community Development Reporting</NavLink></li>
                  </ul>
                )}
              </li>

              {/* Working Areas Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('workingarea')}
                onMouseEnter={() => handleHover('workingarea', true)}
                onMouseLeave={() => handleHover('workingarea', false)}
              >
                <span className="nav-link dropdown-toggle" id="workingareaDropdown">Working Areas</span>
                {dropdownsOpen.workingarea && (
                  <ul className="dropdown-menu show">
                    <li><NavLink className="dropdown-item" to="/Workmanagement">Management</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Workbusiness">Business</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Workcommunity">Community</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Workdevelopment">International Growth and Development</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Worktraining">Training</NavLink></li>
                  </ul>
                )}
              </li>

              {/* Gallery */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/Gallery">Gallery</NavLink>
              </li>

              {/* Downloads Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('download')}
                onMouseEnter={() => handleHover('download', true)}
                onMouseLeave={() => handleHover('download', false)}
              >
                <span className="nav-link dropdown-toggle" id="downloadDropdown">Downloads</span>
                {dropdownsOpen.download && (
                  <ul className="dropdown-menu show">
                    <li>
                      <a className="dropdown-item" href={memberform} download="Member Registration Form_20241211_120040_0000.pdf">
                        Member registration form
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>

          {/* Toggle Button for Mobile */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
          >
            <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </button>
        </div>
      </nav>

      {/* Social Icons and Registration */}
      <div className="social-icons">
        <span><NavLink to="/Form">Member Registration</NavLink></span>
        <a href="/" target="_blank" className="mx-2"><i className="fab fa-facebook-f"></i></a>
        <a href="/" target="_blank" className="mx-2"><i className="fab fa-instagram"></i></a>
        <a href="/" target="_blank" className="mx-2"><i className="fab fa-youtube"></i></a>
      </div>
    </div>
  );
};

export default TopContainer;
