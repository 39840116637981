import React, { useEffect } from "react";
import 'animate.css';
import WOW from 'wowjs';
import './AboutJCI.css';

import india from './assets/images/aboutamravati.jpg';


const AboutJCI = () => {
    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
      }, []);
    return (
        <div className="container about-jci-p-container">
            <div className="row">
                <div className="col-12 col-sm-12 col-md-4 india-image wow animate__animated animate__fadeInLeft mb-5">
                <h4 className="mb-4">ABOUT JCI AMRAVATI</h4>
                    
                    <img src={india} alt="" />
                </div>
                <div className="col-12 col-sm-12 col-md-8 india-text wow animate__animated animate__fadeInRight">
                    <h5 className="text-start" >Welcome to India's largest organization with young and active leaders</h5>

                    <p> JCI Amravati is a voluntary organization, membership based NGO working in India since 1949 for developing the leadership skills of young men and women of this country. It is affiliated to Junior Chamber International(JCI),a worldwide federation of young leaders and entrepreneurs founded in 1944, having headquarter at Chester Field USA. Currently it has over 200,000 active members and more than one million graduates, in over 100 countries and 6,000 communities. </p>

                    <p> JCI Amravati is oldest group of Junior Chamber International since 1959. Currently we are active working in Amravati Region, JCI also expanded in more than 26 states and union territories across India. </p>

                    <p> The membership is offered to everybody regardless of color, cast and creed between the age of 18 -40 years. Junior Chamber International India is registered under Societies Registration Act, Bombay Public Trust Act and Income Tax Act of India. </p>

                    <p> In the last 64 years we are able to produce thousands of social and business leaders all over the country through our intensive project based training activities </p>
                    
                    

                </div>
            </div>


        </div>
    )
}


export default AboutJCI;