import React, { useState, useEffect } from "react";
import "./Workareas.css";
import { ChevronLeft } from "lucide-react";

// Details Component
const ActivityDetails = ({ activity, onClose }) => {
    const [imageUrls, setImageUrls] = useState([]);
    const [youtubeThumbnails, setYoutubeThumbnails] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null); // For the selected video

    useEffect(() => {
        // Fetch image URLs if available
        if (activity.imagename) {
            const fetchImageUrls = async () => {
                const baseUrl =
                    "https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati";
                const imageNames = activity.imagename.split(",");
                const mappedImages = imageNames.map((name) => ({
                    url: `${baseUrl}/${name}?q=50%&&f=webp`,
                    id: name,
                }));
                setImageUrls(mappedImages);
            };
            fetchImageUrls();
        }

        // Fetch YouTube Thumbnails if available
        if (activity.youtubeUrls && activity.youtubeUrls.length > 0) {
            const youtubeLinks = Array.isArray(activity.youtubeUrls)
                ? activity.youtubeUrls
                : JSON.parse(activity.youtubeUrls);

            const thumbnails = youtubeLinks.map((url) => {
                const videoId = new URL(url).searchParams.get("v"); // Extract video ID
                return {
                    id: videoId,
                    thumbnail: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
                    url,
                };
            });
            setYoutubeThumbnails(thumbnails);
        }
    }, [activity]);

    return (
        <div className="activity-details bg-light shadow-lg p-4 rounded">
            <div className="activity-header d-flex align-items-center mb-4">
                <button onClick={onClose} className="btn work-button me-auto">
                    <ChevronLeft />
                </button>
                <h1 className="text-uppercase m-0 text-center flex-grow-1">
                    {activity.title}
                </h1>
            </div>

            <div className="text-secondary">
                <div className="d-flex mb-4 w-50 mx-auto justify-content-center gap-5 text-center">
                    <p className="fs-5 fw-semibold">
                        Date: {new Date(activity.date).toLocaleDateString("en-GB")}
                    </p>
                    <p className="fs-5 fw-semibold">Time: {activity.time}</p>
                    <p className="fs-5 fw-semibold">Location: {activity.location}</p>
                </div>

                <div className="text-md text-white">
                    {activity.description.split("\n").map((paragraph, index) => (
                        <p key={index} className="fs-5">
                            {paragraph}
                        </p>
                    ))}
                </div>

                {/* Render Images */}
                <div className="activity-images mt-4">
                    <h3 className="text-center mb-3">Images</h3>
                    <div className="d-flex flex-wrap gap-3 justify-content-center">
                        {imageUrls.length > 0 ? (
                            imageUrls.map((image, index) => (
                                <img
                                    key={index}
                                    src={image.url}
                                    alt={`Activity Image ${index + 1}`}
                                    className="rounded shadow-sm"
                                    style={{
                                        width: "360px",
                                        height: "250px",
                                        objectFit: "cover",
                                    }}
                                />
                            ))
                        ) : (
                            <p className="text-muted">No images available</p>
                        )}
                    </div>
                </div>

                {/* Render YouTube Thumbnails */}
                <div className="youtube-thumbnails mt-5">
                    <h3 className="text-center mb-3">Videos</h3>
                    <div className="d-flex flex-wrap gap-3 justify-content-center">
                        {youtubeThumbnails.length > 0 ? (
                            youtubeThumbnails.map((video) => (
                                <img
                                    key={video.id}
                                    src={video.thumbnail}
                                    alt="YouTube Thumbnail"
                                    className="rounded shadow-sm cursor-pointer"
                                    style={{
                                        width: "360px",
                                        height: "250px",
                                        objectFit: "cover",
                                    }}
                                    onClick={() => setSelectedVideo(video.url)}
                                />
                            ))
                        ) : (
                            <p className="text-muted">No videos available</p>
                        )}
                    </div>
                </div>

                {/* Render YouTube Lightbox */}
                {selectedVideo && (
                    <div className="youtube-lightbox">
                        <div
                            className="overlay"
                            onClick={() => setSelectedVideo(null)}
                        ></div>
                        <div className="lightbox-content">
                            <iframe
                                width="800"
                                height="450"
                                src={selectedVideo.replace("watch?v=", "embed/")}
                                title="YouTube video"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                            <button
                                className="close-lightbox"
                                onClick={() => setSelectedVideo(null)}
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* Lightbox Styling */}
            <style jsx>{`
                .youtube-lightbox {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.8);
                }
                .lightbox-content {
                    position: relative;
                    z-index: 1001;
                    background: #fff;
                    padding: 16px;
                    border-radius: 12px;
                    max-width: 90%;
                }
                .close-lightbox {
                    position: absolute;
                    top: -16px;
                    right: -16px;
                    background: #f00;
                    color: #fff;
                    border: none;
                    border-radius: 50%;
                    font-size: 24px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                @media (max-width: 768px) {
                    .lightbox-content iframe {
                        width: 100%;
                        height: auto;
                    }
                }
            `}</style>
        </div>
    );
};

const Worktraining = () => {
    const [filter, setFilter] = useState("all");
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await fetch(
                    "https://jciamravati.in/api/v1/workingareas/gettraining"
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch activities");
                }
                const data = await response.json();
                setActivities(data.data || []);
            } catch (err) {
                console.error("Error fetching activities:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, []);

    const filteredActivities =
        filter === "all"
            ? activities
            : activities.filter((item) => item.date.includes(filter));

    return (
        <div className="container-fluid workmanagement-parent py-5">
            <div className="container">
                {selectedActivity ? (
                    <ActivityDetails
                        activity={selectedActivity}
                        onClose={() => setSelectedActivity(null)}
                    />
                ) : (
                    <div className="text-center">
                        <h2 className="mb-4">WORKING AREA : TRAINING</h2>

                        <div className="d-flex justify-content-center mb-4 flex-wrap gap-2">
                            <button
                                className="work-button me-2"
                                onClick={() => setFilter("all")}
                            >
                                All Activities
                            </button>
                            <button
                                className="work-button me-2"
                                onClick={() => setFilter("2025")}
                            >
                                2025
                            </button>
                            <button
                                className="work-button"
                                onClick={() => setFilter("2024")}
                            >
                                2024
                            </button>
                        </div>

                        {loading ? (
                            <p className="text-muted">Loading activities...</p>
                        ) : error ? (
                            <p className="text-danger">{error}</p>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-striped table-hover shadow-sm rounded">
                                    <thead className="table-primary">
                                    <tr>
                                        <th className="text-center py-3">Date</th>
                                        <th className="text-center py-3">Event</th>
                                        <th className="text-center py-3">Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredActivities.map((activity, index) => (
                                        <tr key={index}>
                                            <td className="text-center align-middle">
                                                {activity.date || "N/A"}
                                            </td>
                                            <td className="text-center align-middle">
                                                {activity.title || "Untitled"}
                                            </td>
                                            <td className="text-center align-middle">
                                                <button
                                                    onClick={() => setSelectedActivity(activity)}
                                                    className="read-more"
                                                >
                                                    Read More
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Worktraining;
