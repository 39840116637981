import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'animate.css';

import TopContainer from './components/TopContainer';
import CarouselContainer from './components/CarouselContainer';
import FeaturesContainer from './components/FeaturesContainer';
import JciContainer from './components/JciContainer';
import JoinContainer from './components/JoinContainer';
import PcContainer from './components/PcContainer';
import WhatContainer from './components/WhatContainer';
import YtContainer from './components/YtContainer';
import GalleryContainer from './components/GalleryContainer';
import ProgramsContainer from './components/ProgramsContainer';
import TagContainer from './components/TagContainer';
import Footer from './components/Footer';
import About from './components/About';
import Gallery from './components/Gallery';
import Form from './components/Form';

import AboutJCI from './components/AboutJCI';

import GeneralMember from './components/Generalmember.js';
import SuperHeroes from './components/SuperHeroes.js';
import PastPresidents from './components/PastPresidents.js';

import ZoneBoard from './components/ZoneBoard.js';

import Workmanagement from "./components/Workmanagement.js";

import Workbusiness from "./components/Workbusiness";

import Worktraining from "./components/Worktraining";
import Workdevelopment  from "./components/Workdevelopment";
import Workcommunity from "./components/Workcommunity";










const Layout = ({ children }) => (
  <>
  <TopContainer />
  <div className="main-content">
    {children}
  </div>  
  <Footer />
  </>
);


const App = ()  => {
  return (
    <Router>



      <Routes>
          <Route path="/" element={
      <Layout>
            
            <CarouselContainer/>
            <FeaturesContainer />
            <JciContainer />
            <JoinContainer />
            <PcContainer />
            <WhatContainer />
            <ProgramsContainer />
            <GalleryContainer />
            <YtContainer />
            <TagContainer />

      </Layout>
            
            
          } />



          <Route path="/About" element={<Layout><About/></Layout>} />
          <Route path="/AboutJCI" element={<Layout><AboutJCI/></Layout>} />


          <Route path="/GeneralMember" element={<Layout><GeneralMember/></Layout>} />
          <Route path="/Past Presidents" element={<Layout><PastPresidents/></Layout>} />
          <Route path="/SuperHeroes" element={<Layout><SuperHeroes/></Layout>} />

          <Route path="/" element={<Layout><ZoneBoard/></Layout>} />
          <Route path="/Gallery" element={<Layout><Gallery/></Layout>} />
          <Route path="/Form" element={<Layout><Form/></Layout>} />
          <Route path="/Workmanagement" element={<Layout><Workmanagement/></Layout>} />
          <Route path="/Workbusiness" element={<Layout><Workbusiness/></Layout>} />

          <Route path="/Workcommunity" element={<Layout><Workcommunity/></Layout>} />
          <Route path="/Workdevelopment" element={<Layout><Workdevelopment/></Layout>} />


          <Route path="/Worktraining" element={<Layout><Worktraining/></Layout>} />



      </Routes>

    </Router>
  );
};

export default App;
