import React, { useEffect } from "react";
import './PcContainer.css';
import 'animate.css';
import WOW from 'wowjs';

import portrait from './assets/images/director.jpg';


const PcContainer = () => {
  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }, []);
  return (
    <div className="container-fluid p-parent-container d-flex align-items-center justify-content-center">
      <div className="container p-child-container">
      <div className="row w-100">
        <div className="col-12 col-md-6 d-flex justify-content-center img-content image-container wow animate__animated animate__fadeInLeft">
          <img src={portrait} alt="President" className="img-fluid"/>
        </div>
        <div className="col-12 col-md-6 content d-flex flex-column text-justify justify-content-center p-0 wow animate__animated animate__fadeInRight">
          <p>
            <strong>PRESIDENT CORNER</strong><br />
            <em>Mr. Deepak Lokhande</em>
            <br />
            Dear Jaycees of Amravati,
            <br />
            I am honored to address you as the President of JCI Amravati for the year 2025. As we stand at the threshold of a new era of leadership and service, I am filled with immense pride and anticipation for the journey that lies ahead.
            <br />
            Our mission at JCI is rooted in the belief that young people possess the power to effect positive change in their communities and beyond. It is this belief that drives our efforts to creating young leaders. We at JCI Amravati will further our mission in 2025 with renewed energy and enthusiasm.
          </p>
          <button className="know-btn">
            Know More
          </button>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default PcContainer;
