import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import './ProgramsContainer.css';

const ProgramsContainer = () => {
    const [submittedData, setSubmittedData] = useState([]);

    // Fetch data from the API and images
    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://jciamravati.in/api/v1/workingareas/getrecord"
            );

            const highlightedData = response.data.data.filter(item => item.highlighted === true);

            // Fetch images for each program
            const dataWithImages = await Promise.all(
                highlightedData.map(async (program) => {
                    const imageUrls = await fetchImageUrls(program.imagename);
                    return { ...program, imageUrls }; // Attach the image URLs to the program data
                })
            );

            setSubmittedData(dataWithImages || []);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch image URLs from the media server
    const fetchImageUrls = async (imagename) => {
        try {
            const baseUrl = "https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati";

            // Split the imagename string into individual filenames
            const imageNames = imagename.split(",");

            // Create the full URLs for each image
            const mappedImages = imageNames.map((name) => ({
                url: `${baseUrl}/${name}?q=50%&&f=webp`,
                id: name,
            }));

            return mappedImages;
        } catch (err) {
            console.error("Error fetching image URLs:", err);
            return [];
        }
    };

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="container-fluid programs-parent-container py-5">
            <Container className="programs-child-container text-center text-md-start">
                <h3 className="mb-4">Highlighted Activities</h3>

                <Row className="g-4"> {/* Added 'g-4' for better gutter spacing */}
                    {submittedData.length === 0 ? (
                        <p>No Highlights</p>
                    ) : (
                        submittedData.map((program, index) => (
                            <Col key={index} xs={12} sm={6} md={4} className="mb-4 program-content text-start">
                                <h5 className="mb-4">{program.title}</h5>

                                <div className="program-image bg-warning">
                                    {/* Display the first image from the list of images */}
                                    {program.imageUrls && program.imageUrls.length > 0 ? (
                                        <img
                                            src={program.imageUrls[0].url} // Display the first image URL
                                            alt={program.title}
                                            className="program-image h-full w-full object-cover"
                                        />
                                    ) : (
                                        <img
                                            src="/assets/images/landscape-image.jpg" // Fallback image if no images available
                                            alt="Fallback"
                                            className="program-image h-full w-full object-cover"
                                        />
                                    )}
                                </div>

                                <h6>Location: {program.location}</h6>
                                <h6>Time: {program.time}</h6>
                                <h6>Date: {program.date}</h6>
                            </Col>
                        ))
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default ProgramsContainer;
